import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from 'internalization/en';
import es from 'internalization/es';
import fr from 'internalization/fr';

i18n
  .use(
    new LanguageDetector(null, {
      lookupLocalStorage: 'langue',
      lookupCookie: 'language',
      caches: ['localStorage', 'cookie'],
    }),
  )
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      cookieDomain: process.env.REACT_APP_ENGARDE_DOMAIN,
      cookieMinutes: 525600,
    },
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      fr: {
        translation: fr,
      },
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr', 'es'],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
